<template>
  <el-dialog
    :modal="false"
    title="限定商品"
    :visible.sync="showDialog"
    width="750px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-table v-loading="loading" stripe :data="list">
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column prop="goods_name" label="商品名称" align="center">
          <template #default="{ row }">
            <goods-search
              ref="goodsSearch"
              :f-key="row.goods_name"
              @select-goods-all="selectGoods($event, row)"
              @add-rows="addRows"
            ></goods-search>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_spec"
          label="规格"
          align="center"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template #default="{ $index, row }">
            <!-- <el-button
              type="text"
              @click.native.prevent="handleAddRow($index, row)"
            >
              添加
            </el-button> -->
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button>导出</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { limitedGoods, setLimitedGoods } from '@/api/placeOrder'
  export default {
    name: 'LimitedGoods',
    components: {
      GoodsSearch,
    },
    data() {
      return {
        id: 0,
        loading: false,
        showDialog: false,
        list: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.list = []
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await limitedGoods({ id: this.id })
        this.list = data
        this.addRow()
        this.loading = false
      },

      handleSave() {
        // 处理list id
        let goods = []
        this.list.forEach((item) => {
          if (item.goods_name !== '') {
            goods.push(item.goods_id)
          }
        })

        setLimitedGoods({ id: this.id, goods: JSON.stringify(goods) }).then(
          (res) => {
            console.log('succ')
            this.$message.success(res.msg)
            this.$emit('refrech')
            this.close()
          }
        )
      },
      // handleAddRow(index, row) {},
      handleDeleteRow(index, row) {
        if (this.list.length !== 1) {
          this.list.splice(index, 1)
        } else {
          if (this.list[index].goods_id !== '') {
            this.list.splice(index, 1)
            this.addRow()
          } else {
            this.$message.error('不能删除最后一行')
          }
        }
      },

      selectGoods(val, row) {
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.goods_spec = val.specs
        if (this.list[this.list.length - 1].goods_name !== '') {
          this.addRow()
        }
      },
      addRows(val) {
        if (val.length !== 0) {
          val.forEach((item) => {
            item.goods_spec = item.specs
            this.list.unshift(item)
            // this.list.splice(0, 0, item)
          })
        }
      },
      addRow() {
        this.list.push({
          goods_id: '',
          goods_name: '',
          goods_spec: '',
          id: '',
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
