<!--
 * @Author: your name
 * @Date: 2021-02-05 15:59:25
 * @LastEditTime: 2021-02-05 16:19:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\placeOrder\components\discount.vue
-->
<template>
  <el-dialog
    :modal="false"
    title="优惠折扣"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form :model="form">
        <el-form-item>
          <el-radio v-model="form.a" label="1">备选项</el-radio>
          <el-radio v-model="form.a" label="2">备选项</el-radio>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item></el-form-item>
        <el-form-item></el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'Discount',
    components: {},
    data() {
      return {
        showDialog: false,
        form: { a: '' },
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
