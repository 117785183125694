import request from '@/utils/request'

// 快捷下单 列表
export function getList(data) {
  return request({
    url: '/baseAdmin/link/index',
    method: 'post',
    data,
  })
}

// 设置 开关
export function changeSwitch(data) {
  return request({
    url: '/baseAdmin/link/status',
    method: 'post',
    data,
  })
}

// 产品 限定列表
export function limitedGoods(data) {
  return request({
    url: '/baseAdmin/link/goods-rel-list',
    method: 'post',
    data,
  })
}

// 设置 限定产品
export function setLimitedGoods(data) {
  return request({
    url: '/baseAdmin/link/goods-set',
    method: 'post',
    data,
  })
}

// 设置 限定员工
export function setStaff(data) {
  return request({
    url: '/baseAdmin/link/user-set',
    method: 'post',
    data,
  })
}

// 员工 限定列表
export function getStaffList(data) {
  return request({
    url: '/baseAdmin/link/user-rel-list',
    method: 'post',
    data,
  })
}
