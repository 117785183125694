<template>
  <el-dialog
    :modal="false"
    title="限定业务员"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <div v-loading="loading" class="wrapper">
        <div class="content">
          <div class="tree">
            <el-tree
              :data="treeData"
              :props="defaultProps"
              node-key="id"
              :default-expanded-keys="[1]"
              highlight-current
              style="height: 500px; overflow: auto"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
          <div class="leftTable">
            <el-table
              v-loading="lTableLoading"
              stripe
              :data="lTableData"
              border
              height="480"
              @selection-change="lTableClick"
            >
              <!-- 选择框 -->
              <el-table-column
                align="center"
                type="selection"
                width="50"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="user_name"
                label="业务员姓名"
                width="auto"
              ></el-table-column>
            </el-table>
            <el-button
              style="float: right; margin-top: 5px"
              type="primary"
              @click="add"
            >
              添加
            </el-button>
          </div>
          <div class="middleImg">
            <i
              class="el-icon-caret-right"
              style="cursor: pointer"
              @click="add"
            ></i>
          </div>
          <div class="rightTable">
            <el-table
              v-loading="rTableLoading"
              stripe
              :data="rTableData"
              border
              height="480"
              @selection-change="rTableClick"
            >
              <el-table-column
                align="center"
                prop="depart_name"
                label="部门"
                width="auto"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="user_name"
                label="业务员姓名"
                width="110"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="mobile"
                label="电话"
                width="110"
              ></el-table-column>
              <el-table-column align="center" prop="" label="操作" width="60">
                <template #default="{ $index }">
                  <el-button type="text" @click="handleDelete($index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { getTree, getUserData } from '@/api/setPrice'
  import { getStaffList } from '@/api/placeOrder'
  export default {
    name: 'LimitedStaff',
    components: {},
    data() {
      return {
        id: 0,
        loading: false,
        showDialog: false,
        lTableLoading: false,
        rTableLoading: false,
        tableRowSelect: [],
        treeData: [],
        lTableData: [],
        rTableData: [],
        defaultProps: {
          children: 'children',
          label: 'depart_name',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          console.log(this.id)
          this.fetchData()
          this.staffList()
        } else {
          this.lTableData = []
          this.loading = true
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 树 数据获取
      async fetchData() {
        let { data, msg, code } = await getTree({ scheme_id: this.id })
        console.log(data)
        if (code == 200) {
          this.treeData = data.depart
        } else {
          this.$message.error(msg)
        }
        this.loading = false
      },
      // 左边表格数据获取
      async getlTable(id) {
        this.lTableLoading = true
        let { data, msg, code } = await getUserData({ depart_id: id })
        console.log(data, code)
        if (code == 200) {
          this.lTableData = data
        } else {
          this.$message.error(msg)
        }
        this.lTableLoading = false
      },
      // 树形节点点击事件
      handleNodeClick(val) {
        this.getlTable(val.id)
      },
      // 左右表格 row 点击事件
      lTableClick(val) {
        console.log(val)
        this.tableRowSelect = val
      },
      rTableClick(val) {
        console.log(val)
      },
      add() {
        let select = this.tableRowSelect
        console.log(select)
        if (select.length == 0) {
          this.$message.error('请选择业务员')
          return false
        }
        select.forEach((item) => {
          // 先判断存不存在相同
          let haveed = this.rTableData.filter(
            (rTdItem) => rTdItem.id == item.id
          )
          console.log(haveed)
          if (haveed.length !== 0) {
            try {
              haveed.forEach((item1) => {
                // this.$message.error('业务员 ' + item1.user_name + ' 已存在')
                // throw new Error('存在相同业务员')
                return false
              })
            } catch (e) {
              if (e.message == '存在相同业务员') {
                throw e
              }
            }
          } else {
            this.rTableData.push(item)
          }
        })
        this.$message.success('添加成功')
      },
      // 删除
      handleDelete(index) {
        console.log('删除', index)
        this.rTableData.splice(index, 1)
      },
      save() {
        let data = this.rTableData
        let userArr = []
        data.forEach((item) => {
          userArr.push(item.id)
        })
        let endData = userArr.join(',')
        console.log(endData)
        this.$emit('set-userid', endData)
        this.$emit('set-user', { endData, id: this.id })
        this.showDialog = false
      },
      staffList() {
        console.log('asdasdasdas')
        getStaffList({ id: this.id }).then((res) => {
          console.log(res)
          this.rTableData = res.data
          console.log(this.rTableData)
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 500px;
  }
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .tree {
    width: 25%;
  }
  .leftTable {
    box-sizing: border-box;
    width: 25%;
    padding: 0 10px;
  }
  .middleImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5%;
    text-align: center;
    i {
      width: 100%;
      font-size: 50px;
      color: #000;
    }
  }
  .rightTable {
    width: 45%;
    padding: 0 10px;
  }
</style>
