var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v(" 说明：快捷下单用于修改业务员端下单时的选项按钮 "),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        _vm._l(_vm.finallyColumns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              prop: item.prop,
              label: item.label,
              width: "",
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                item.label == "限定商品"
                  ? {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.is_goods_edit == 1
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      "text-decoration": "underline",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row.goods_desc) + " ")]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.xdspEdit(row)
                                    },
                                  },
                                }),
                              ])
                            : _c("div", [
                                _vm._v(" " + _vm._s(row.goods_desc) + " "),
                              ]),
                        ]
                      },
                    }
                  : item.label == "优惠折扣"
                  ? {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.is_zhekou_edit == 1
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      "text-decoration": "underline",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row.zhekou_desc) + " ")]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.yhzkEdit(row)
                                    },
                                  },
                                }),
                              ])
                            : _c("div", [
                                _vm._v(" " + _vm._s(row.zhekou_desc) + " "),
                              ]),
                        ]
                      },
                    }
                  : item.label == "显示开关"
                  ? {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  row.is_close == 1 ? "点击开启" : "点击关闭",
                                enterable: false,
                                placement: "top",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "inactive-value": 1,
                                  "active-value": 0,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.showChange(row)
                                  },
                                },
                                model: {
                                  value: row.is_close,
                                  callback: function ($$v) {
                                    _vm.$set(row, "is_close", $$v)
                                  },
                                  expression: "row.is_close",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : item.label == "限定业务员"
                  ? {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "5px",
                                "text-decoration": "underline",
                              },
                            },
                            [_vm._v(" " + _vm._s(row.num_user) + " ")]
                          ),
                          _c("i", {
                            staticClass: "el-icon-edit-outline",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.xdywyEdit(row)
                              },
                            },
                          }),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("limited-goods", {
        ref: "limitedGoods",
        on: { refrech: _vm.fetchData },
      }),
      _c("discount", { ref: "discount" }),
      _c("limited-staff", {
        ref: "limitedStaff",
        on: { "set-user": _vm.setUserId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }