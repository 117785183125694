<template>
  <div class="orderTest-container">
    <div style="margin-bottom: 10px">
      说明：快捷下单用于修改业务员端下单时的选项按钮
    </div>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template v-if="item.label == '限定商品'" #default="{ row }">
          <div v-if="row.is_goods_edit == 1">
            <span style="margin-right: 5px; text-decoration: underline">
              {{ row.goods_desc }}
            </span>
            <i
              class="el-icon-edit-outline"
              style="cursor: pointer"
              @click="xdspEdit(row)"
            ></i>
          </div>
          <div v-else>
            {{ row.goods_desc }}
          </div>
        </template>
        <template v-else-if="item.label == '优惠折扣'" #default="{ row }">
          <div v-if="row.is_zhekou_edit == 1">
            <span style="margin-right: 5px; text-decoration: underline">
              {{ row.zhekou_desc }}
            </span>
            <i
              class="el-icon-edit-outline"
              style="cursor: pointer"
              @click="yhzkEdit(row)"
            ></i>
          </div>
          <div v-else>
            {{ row.zhekou_desc }}
          </div>
        </template>
        <template v-else-if="item.label == '显示开关'" #default="{ row }">
          <el-tooltip
            :content="row.is_close == 1 ? '点击开启' : '点击关闭'"
            :enterable="false"
            placement="top"
          >
            <el-switch
              v-model="row.is_close"
              :inactive-value="1"
              :active-value="0"
              @change="showChange(row)"
            ></el-switch>
          </el-tooltip>
        </template>
        <template v-else-if="item.label == '限定业务员'" #default="{ row }">
          <span style="margin-right: 5px; text-decoration: underline">
            {{ row.num_user }}
          </span>
          <i
            class="el-icon-edit-outline"
            style="cursor: pointer"
            @click="xdywyEdit(row)"
          ></i>
        </template>
        <!-- <template v-else-if="item.label == '限定终端'" #default="{ row }">
          <span style="margin-right: 5px; text-decoration: underline">
            {{ row.num_cust }}
          </span>
          <i
            class="el-icon-edit-outline"
            style="cursor: pointer"
            @click="xdzdEdit(row)"
          ></i>
        </template> -->
      </el-table-column>
    </el-table>
    <limited-goods ref="limitedGoods" @refrech="fetchData"></limited-goods>
    <discount ref="discount"></discount>
    <limited-staff ref="limitedStaff" @set-user="setUserId"></limited-staff>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList, changeSwitch, setStaff } from '@/api/placeOrder'
  import LimitedGoods from './components/limitedGoods'
  import Discount from './components/discount'
  import LimitedStaff from './components/limitedStaff'
  export default {
    name: 'PlaceOrder',
    components: {
      LimitedGoods,
      Discount,
      LimitedStaff,
    },
    data() {
      return {
        loading: false,
        checkList: [
          '快捷菜单',
          '限定商品',
          '优惠折扣',
          '显示开关',
          '限定业务员',
        ],
        columns: [
          {
            order: 1,
            label: '快捷菜单',
            prop: 'link_name',
            width: '',
          },
          {
            order: 2,
            label: '限定商品',
            prop: 'goods_desc',
            width: '',
          },
          {
            order: 3,
            label: '优惠折扣',
            prop: 'zhekou_desc',
            width: '',
          },
          {
            order: 4,
            label: '显示开关',
            prop: 'is_close',
            width: '',
          },
          {
            order: 5,
            label: '限定业务员',
            prop: 'num_user',
            width: '',
          },
        ],
        list: [
          {
            id: 16,
            link_name: '赠品',
            goods_desc: '1',
            zhekou_desc: '无',
            is_goods_edit: 1,
            is_zhekou_edit: 1,
            num_user: '全部',
            num_cust: '全部',
            is_close: 1,
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await getList()
        console.log(data)
        this.list = data
        this.loading = false
      },
      xdspEdit(row) {
        this.$refs.limitedGoods.id = row.id
        this.$refs.limitedGoods.showDialog = true
      },
      yhzkEdit(row) {
        this.$refs.discount.id = row.id
        this.$refs.discount.showDialog = true
      },
      showChange(row) {
        changeSwitch({ id: row.id, is_close: row.is_close }).then((res) => {
          this.$message.success(res.msg)
        })
      },
      xdywyEdit(row) {
        this.$refs.limitedStaff.id = row.id
        this.$refs.limitedStaff.showDialog = true
      },
      // xdzdEdit(row) {},
      setUserId(val) {
        console.log(val)
        let user
        if (val.endData) {
          user = JSON.stringify(val.endData.split(','))
        } else {
          user = ''
        }
        console.log(user)
        setStaff({
          id: val.id,
          user,
        }).then((res) => {
          console.log(res)
          this.fetchData()
          this.$message.success('保存成功')
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
