var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "优惠折扣",
        visible: _vm.showDialog,
        width: "500px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "a", $$v)
                        },
                        expression: "form.a",
                      },
                    },
                    [_vm._v("备选项")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.form.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "a", $$v)
                        },
                        expression: "form.a",
                      },
                    },
                    [_vm._v("备选项")]
                  ),
                ],
                1
              ),
              _c("el-form-item"),
              _c("el-form-item"),
              _c("el-form-item"),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }